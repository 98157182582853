<template>
  <header class="header container" :class="{ white: enableWhiteHeader }">
    <router-link to="/">
      <img
        v-if="enableWhiteHeader"
        width="170"
        src="https://assets.academyb1.ru/images/logo/logo_website_white_ru_txt_right.png"
        alt="Академия бизнеса Б1"
      />
      <img
        v-else
        width="170"
        src="https://assets.academyb1.ru/images/logo/logo_website_color_ru_txt_right.png"
        alt="Академия бизнеса Б1"
      />
    </router-link>
    <nav class="header-navbar">
      <a
        href="#catalog" class="header-nav-point" @click="navigateToLocal"
      >Каталог</a>
      <a
        href="#speakers" class="header-nav-point" @click="navigateToLocal"
      >Ведущие</a>
    </nav>
    <a class="header-nav-point" href="tel:74957559840">+7 (495) 755-98-40</a>
  </header>
</template>

<script>
export default {
  name: 'MainHeader',
  computed: {
    enableWhiteHeader() {
      return this.$route.path !== '/' && this.$route.path !== '/404';
    },
  },
  methods: {
    navigateToLocal(evt) {
      evt.preventDefault();
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
      setTimeout(() => {
        const targetSectionId = evt.target.href.split('#')[1];
        const targetSection = document.querySelector(`#${targetSectionId}`);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 125px;
  /*margin-bottom: -125px;*/
  background-color: transparent;
  color: #2e2e38;

  &.white {
    color: white !important;
  }

  &-navbar {
    display: flex;
    flex-grow: 0.1;
    justify-content: space-around;
  }

  &-nav-point {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: inherit;
    text-decoration: none;
    transition: 0.2s ease-out;
  }

  &-nav-point:hover,
  &-nav-point:focus {
    opacity: 0.6;
    transition: 0.2s ease-out;
  }

  @media (max-width: 767px) {
    &-navbar,
    &-nav-point {
      display: none;
    }
  }
}
</style>
