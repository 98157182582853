import axios from 'axios';

const DEFAULT_SUBJECT = 'Обратный звонок';
const MAIL_URL = './ajax/sendMail.php';

export default async function ({
  subject = DEFAULT_SUBJECT,
  clientEmail,
  clientName,
  clientPhone,
  notifications,
  confirmTerms,
  successCallback,
  errorCallback,
}) {
  const data = {
    clientPhone,
    clientEmail,
    clientName,
    notifications,
    confirmTerms,
    subject,
  };

  await axios.post(MAIL_URL, JSON.stringify(data))
    .then((response) => {
      if (process.env.NODE_ENV === 'development') console.log(response);
      if (successCallback) successCallback();
    })
    .catch((error) => {
      console.warn(error);
      if (errorCallback) errorCallback();
    });
}
