<template>
  <section class="container">
    <h2>Почему бизнес-игры это лучший формат?</h2>
    <p class="subheading">Преимущества бизнес-игр</p>

    <article class="features background-02 container">
      <div
        v-for="feature in features"
        :key="feature.itemId"
        class="features-item"
      >
        <h3>{{ feature.heading }}</h3>
        <ul class="list">
          <li v-for="item in feature.list" :key="item">
            <p>{{ item }}</p>
          </li>
        </ul>
      </div>
    </article>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MainFeatures',
  computed: {
    ...mapState(['features']),
  },
};
</script>

<style lang="scss" scoped>
.features {
  display: grid;
  margin-bottom: 80px;
      grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 46px;

  &-item {
    color: #2e2e38;
    padding: 66px 70px;
    background: #ffffff;
    box-shadow: 0 16px 35px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
  }

  &-item {
    padding: 24px;
  }

  @media (min-width: 576px) {
    &-item {
      padding: 46px 60px;
    }
  }

  @media (min-width: 768px) {
    & {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }

    &-item {
      padding: 36px 46px;
    }
  }

  @media (min-width: 992px) {
    & {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 36px;
      grid-row-gap: 36px;
    }

    &-item {
      padding: 46px 56px;
    }
  }

  @media (min-width: 1200px) {
    &-item {
      padding: 66px 70px;
    }
  }
}
</style>
