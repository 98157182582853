import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import components from '@/components';
import '@/styles/root.scss';
import '@/styles/common.scss';

Vue.config.productionTip = false;

Vue.use(Vuelidate);

Object.keys(components).forEach((key) => {
  Vue.component(key, components[key]);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

/**
 * TODO:
 * --Правильный футер--
 * --Ребрендинг--
 * --Стили, лого и прочее от Б1
 * --Цвет шапки в зависимости от страницы
 * --Ошибки в консоли
 * --Проблемы со скроллом
 * --Навигация в шапке
 * --404
 * --Страницы игр
 * --Фильтрация
 * --Фавиконка
 * --Привести в порядок спикеров: компонент, список, картинки
 * --Привести в порядок карточки игр: список, картинки
 * --Кнопка «Назад» на странице игры должна вести к той же карточке в списке
 * --Упростить классы и стили
 * --Страница игры:
 * --Шапка для страницы игр
 * --Хлебные крошки для страницы игр
 * --Доп. инфа для страницы игр
 * --CallbackForm
 * --GameForm
 * --Довести до ума страницу 404
 * --Проверить адаптив
 * --Спикеры, игры и фичи из внешних файлов
 * --Общее подключение компонентов
 * Отправка писем
 * Обновить контент
 */
