<template>
  <section id="callback" class="container">
    <form class="callback" @submit.prevent="onSubmit">
      <div v-if="isError || isLoading || isSuccess" class="fade">
        <template v-if="isError">
          <p>
            Что-то пошло не так. <br />
            <small> Пожалуйста, попробуйте позднее. </small>
          </p>
        </template>
        <template v-if="isLoading">
          <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </template>
        <template v-if="isSuccess">
          <p>
            Успешно отправлено! <br />
            <small> Скоро с Вами свяжется наш специалист. </small>
          </p>
        </template>
      </div>
      <label class="callback-control">
        Имя и Фамилия
        <input v-model="name" type="text" @blur="$v.name.$touch" />
        <div v-show="$v.name.$error" class="validation-message">
          Обязательное поле
        </div>
      </label>

      <label class="callback-control">
        E-mail
        <input v-model="email" type="email" @blur="$v.email.$touch" />
        <div v-show="$v.email.$error" class="validation-message">
          Обязательное поле
        </div>
      </label>

      <label class="callback-control">
        Телефон
        <input
          v-model="phone"
          v-mask="'+7 (###) ###-##-##'"
          type="text"
          @keydown="onPhoneInput"
          @blur="$v.phone.$touch"
        />
        <div v-show="$v.phone.$error" class="validation-message">
          Обязательное поле
        </div>
      </label>

      <label class="callback-checkbox">
        <input v-model="bulk" type="checkbox" />
        <span>Получать полезную рассылку от Академии бизнеса Б1</span>
      </label>

      <label
        class="callback-checkbox"
        :class="{ invalid: $v.processing.$error }"
      >
        <input v-model="processing" type="checkbox" />
        <span>
          <div>
            Согласие на
            <a href="https://academyb1.ru/legal/#politika-operatora-v-otnoshenii-obrabotki-personalnykh-dannykh" target="_blank">
              обработку персональных данных
            </a>
          </div>
        </span>
      </label>

      <input v-model="utm_source" type="hidden" />

      <input v-model="utm_medium" type="hidden" />

      <input v-model="utm_campaign" type="hidden" />

      <input v-model="utm_content" type="hidden" />

      <input v-model="utm_term" type="hidden" />

      <button class="callback-submit">Отправить</button>
    </form>
  </section>
</template>
<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import sendMail from '@/tools/send-mail';

const PHONE_REG_EXP = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
const phone = (value) => PHONE_REG_EXP.test(value);
const checkbox = (value) => value === true;

export default {
  name: 'CallbackForm',
  directives: { mask },
  data() {
    return {
      name: '',
      email: '',
      phone: '+7',
      bulk: false,
      processing: false,
      isLoading: false,
      isError: false,
      isSuccess: false,
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_content: '',
      utm_term: '',
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.utm_source = urlParams.get('utm_source');
    this.utm_medium = urlParams.get('utm_medium');
    this.utm_campaign = urlParams.get('utm_campaign');
    this.utm_content = urlParams.get('utm_content');
    this.utm_term = urlParams.get('utm_term');
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
    },
    processing: {
      checkbox,
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
      phone,
    },
  },
  methods: {
    onPhoneInput(evt) {
      if (evt.target.value === '+7' && evt.key === 'Backspace') {
        evt.preventDefault();
      }
    },
    successCallback() {
      this.isLoading = false;
      this.isSuccess = true;
    },
    errorCallback() {
      this.isLoading = false;
      this.isError = true;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$error || this.isError || this.isLoading || this.isSuccess) return;
      this.isLoading = true;
      const { successCallback, errorCallback } = this;

      sendMail({
        clientName: this.name,
        clientPhone: this.phone,
        clientEmail: this.email,
        notifications: this.bulk,
        confirmTerms: this.processing,
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        utm_content: this.utm_content,
        utm_term: this.utm_term,

        successCallback,
        errorCallback,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.callback {
  background-image: linear-gradient(170.54deg, #8088ff 0%, #5058ce 100%);
  box-shadow: 2px 0 25px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  max-width: 540px;
  padding: 48px 48px 32px;
  margin: 0 auto 120px;
  position: relative;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    max-width: 100%;
    margin: 0 0 120px;
    align-items: center;
  }
}

.fade {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(170.54deg, #8088ff 0%, #5058ce 100%);
  z-index: 10;
  border-radius: 25px;
  color: white;
  text-align: center;
  p {
    font-size: 20px;
  }
}

.callback-control {
  display: block;
  height: 48px;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;

  position: relative;

  input {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #ffffff;

    margin-top: 12px;
    padding-bottom: 4px;
    background: none;
    border: 0;
    border-bottom: 2px solid #ffffff;
    outline: none;

    position: absolute;
    left: 0;
    bottom: 0;
  }

  .validation-message {
    position: absolute;
    bottom: -21px;
    font-size: 14px;
    font-weight: 400;
    color: #ffcf87;
  }
}

.callback-checkbox {
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  color: #ffffff;
  transition: 0.2s ease-out;
  position: relative;

  a {
    color: white;
  }

  &.invalid {
    color: #ffcf87;
  }

  input {
    display: none;
    transition: 0.1s ease-out;
  }

  span {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  span::before {
    flex-shrink: 0;
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 4px;
    transition: 0.1s ease-out;
  }

  input:checked + span::before {
    background-color: #ffffff;
    background-image: url('../assets/images/check.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  input:not(:checked):hover + span::before,
  input:not(:checked):focus + span::before {
    background-color: #ffffff;
  }
}

.callback-submit {
  margin: 0 auto;
  width: 100%;
  max-width: 284px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  outline: 0;
  border: 0;
  border-radius: 10px;
  padding: 14px 24px;
  transition: 0.2s ease-out;
  cursor: pointer;

  @media (min-width: 992px) {
    max-width: 100%;
  }

  &:hover,
  &:focus {
    box-shadow: none;
    opacity: 0.8;
  }

  &:active {
    background: #2e2e38;
    color: #ffffff;
  }
}
</style>
