<template>
  <footer class="footer">
    <a href="https://academyb1.ru" target="_blank">
      <img class="footer-logo mb-3" src="https://assets.academyb1.ru/images/logo/logo_website_wite_ru_clean.png" width="50" alt="Б1_Logo">
    </a>
    <span class="footer-copyright">
      © {{ currentYear }} Академия бизнеса Б1
      <br />
      Все права защищены
    </span>
    <a target="_blank" class="footer-link" href="https://academyb1.ru/legal/#politika-operatora-v-otnoshenii-obrabotki-personalnykh-dannykh">
      Политика Оператора в отношении обработки персональных данных
    </a>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>

.footer {
  margin: 0;
  padding-top: 96px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: #2E2E38;

  &-logo {
    margin-bottom: 16px;
  }

  &-copyright {
    font-size: 14px;
    font-family: "Montserrat", "Roboto", sans-serif;
    font-weight: 100;
    color: #ffffff;
    margin: 0 0 16px;
    line-height: 1.4;
  }

  &-link {
    font-size: 14px;
    font-family: "Montserrat", "Roboto", sans-serif;
    font-weight: 100;
    color: #ffffff;
    margin: 0 0 8px;
    text-decoration: underline;
    text-decoration-color: white;
    transition: 0.2s ease-out;
  }

  &-link:hover {
    text-decoration-color: #4AA851;
  }
}
</style>
