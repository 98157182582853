<template>
  <div id="app">
    <div v-if="isLoading || isError" class="fade">
      <template v-if="isLoading && !isError">
        <div>
          <div class="spinner-border text-green" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <p>Извините, что-то пошло не так. </p>
          <p>Пожалуйста, попробуйте посетить сайт позднее.</p>
        </div>
      </template>
    </div>
    <template v-else>
      <main-header />
      <route-guard>
        <transition name="slide-fade" mode="out-in">
          <router-view />
        </transition>
      </route-guard>
      <main-footer />
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      isLoading: true,
      isError: false,
    };
  },
  mounted() {
    Promise.all([this.getSpeakers(), this.getGames(), this.getFeatures()]).then(() => {
      this.isLoading = false;
    }).catch((err) => {
      this.isError = true;
      console.warn(err);
    });
  },
  methods: mapActions(['getSpeakers', 'getGames', 'getFeatures']),
};
</script>

<style lang="scss">
.fade {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 10;
  color: #4AA851;
  text-align: center;
  p {
    font-weight: 700;
    font-size: 20px;
  }
}
</style>
