<template>
  <form class="application" @submit.prevent="onSubmit">
    <div v-if="isError || isLoading || isSuccess" class="fade">
      <template v-if="isError">
        <p>
          Что-то пошло не так. <br />
          <small> Пожалуйста, попробуйте позднее. </small>
        </p>
      </template>
      <template v-if="isLoading">
        <div class="spinner-border text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </template>
      <template v-if="isSuccess">
        <p>
          Успешно отправлено! <br />
          <small> Скоро с Вами свяжется наш специалист. </small>
        </p>
      </template>
    </div>
    <h3 class="application-heading">Оставить заявку</h3>
    <label class="application-input">
      Имя и Фамилия
      <input v-model="name" type="text" @blur="$v.name.$touch" />
      <div v-show="$v.name.$error" class="validation-message">
        Обязательное поле
      </div>
    </label>
    <label class="application-input">
      E-mail
      <input v-model="email" type="email" @blur="$v.email.$touch" />
      <div v-show="$v.email.$error" class="validation-message">
        Обязательное поле
      </div>
    </label>
    <label class="application-input">
      Телефон
      <input
        v-model="phone"
        v-mask="'+7 (###) ###-##-##'"
        type="text"
        @keydown="onPhoneInput"
        @blur="$v.phone.$touch"
      />
      <div v-show="$v.phone.$error" class="validation-message">
        Обязательное поле
      </div>
    </label>
    <label class="application-checkbox">
      <input v-model="bulk" type="checkbox" />
      <span>Получать полезную рассылку от Академии бизнеса Б1</span>
    </label>
    <label
      class="application-checkbox"
      :class="{ invalid: $v.processing.$error }"
    >
      <input v-model="processing" type="checkbox" />
      <span>
        <div>
          Согласие с условиями
          <a href="https://academyb1.ru/legal/#politika-operatora-v-otnoshenii-obrabotki-personalnykh-dannykh" target="_blank">
            обработки персональных данных
          </a>
        </div>
      </span>
    </label>
    <input v-model="utmSource" type="hidden" />
    <input v-model="utmMedium" type="hidden" />
    <input v-model="utmCampaign" type="hidden" />
    <input v-model="utmContent" type="hidden" />
    <input v-model="utmTerm" type="hidden" />

    <button type="submit" class="application-submit button button_main w-100">
      Играть
    </button>
  </form>
</template>
<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import sendMail from '@/tools/send-mail';

const PHONE_REG_EXP = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
const phone = (value) => PHONE_REG_EXP.test(value);
const checkbox = (value) => value === true;

export default {
  name: 'GameForm',
  directives: { mask },
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      email: '',
      phone: '+7',
      bulk: false,
      processing: false,
      isLoading: false,
      isError: false,
      isSuccess: false,
      utmSource: '',
      utmMedium: '',
      utmCampaign: '',
      utmContent: '',
      utmTerm: '',

    };
  },
  created() {
    const url = new URL(window.location.href);

    this.utmSource = url.searchParams.get('utm_source') || '';
    this.utmMedium = url.searchParams.get('utm_medium') || '';
    this.utmCampaign = url.searchParams.get('utm_campaign') || '';
    this.utmContent = url.searchParams.get('utm_content') || '';
    this.utmTerm = url.searchParams.get('utm_term') || '';
  },

  validations: {
    name: {
      required,
      minLength: minLength(2),
    },
    processing: {
      checkbox,
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
      phone,
    },
  },
  methods: {
    onPhoneInput(evt) {
      if (evt.target.value === '+7' && evt.key === 'Backspace') {
        evt.preventDefault();
      }
    },
    successCallback() {
      this.isLoading = false;
      this.isSuccess = true;
    },
    errorCallback() {
      this.isLoading = false;
      this.isError = true;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$error || this.isError || this.isLoading || this.isSuccess) return;
      this.isLoading = true;
      const { successCallback, errorCallback } = this;
      const subject = `заявка на «${this.game.title}»`;

      sendMail({
        clientName: this.name,
        clientPhone: this.phone,
        clientEmail: this.email,
        notifications: this.bulk,
        confirmTerms: this.processing,
        utmSource: this.utmSource,
        utmMedium: this.utmMedium,
        utmCampaign: this.utmCampaign,
        utmContent: this.utmContent,
        utmTerm: this.utmTerm,

        successCallback,
        errorCallback,
        subject,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.fade {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(170.54deg, #5abd62 0%, #4aa851 100%);
  z-index: 10;
  border-radius: 25px;
  color: white;
  text-align: center;
  p {
    font-size: 20px;
  }
}
.application {
  min-width: 290px;
  max-width: 415px;
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0px 16px 35px rgba(0, 0, 0, 0.2);
  padding: 42px 34px 46px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  position: relative;

  @media (min-width: 992px) {
    padding: 42px 54px 46px;
  }
}

.application-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  color: #2e2e38;
  margin-bottom: 46px;
}

.application-input {
  display: block;
  height: 48px;
  width: 100%;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #676773;

  position: relative;
  margin-bottom: 36px;

  input {
    display: block;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #2e2e38;
    font-family: 'Montserrat', sans-serif;
    border-bottom: 1px solid #676773;

    margin-top: 12px;
    padding-bottom: 4px;
    background: none;
    outline: none;

    position: absolute;
    left: 0;
    bottom: 0;
  }

  .validation-message {
    position: absolute;
    bottom: -21px;
    font-size: 14px;
    font-weight: 400;
    color: #fd482b;
  }
}

.application-checkbox {
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  color: #2e2e38;
  transition: 0.2s ease-out;
  position: relative;

  a {
    color: #2e2e38;
  }

  &.invalid {
    color: #fd482b;
  }

  input {
    display: none;
    transition: 0.1s ease-out;
  }

  span {
    display: flex;
    align-items: center;
  }

  span::before {
    flex-shrink: 0;
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    background-color: transparent;
    border: 1px solid #4aa851;
    border-radius: 4px;
    transition: 0.1s ease-out;
  }

  input:checked + span::before {
    background-color: #4aa851;
    background-image: url('../assets/images/check-white.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  input:not(:checked):hover + span::before,
  input:not(:checked):focus + span::before {
    background-color: #4aa851;
  }
}

.application-disclaimer {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #2e2e38;
  margin-bottom: 36px;
}
</style>
