import Vue from 'vue';
import VueRouter from 'vue-router';
import Сatalog from '@/views/Сatalog.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Сatalog',
    component: Сatalog,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/Page404.vue'),
  },
  {
    path: '/:gid',
    name: 'Game',
    component: () => import('@/views/Game.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

router.afterEach((to, from) => {
  if (from.path === '/' && to.path !== '/') {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    return;
  }

  if (from.path !== '/404' && to.path === '/') {
    setTimeout(() => {
      const { gid } = from.params;
      const targetCard = document.querySelector(`#${gid}`);
      if (targetCard) {
        window.scrollTo(0, 0);
        targetCard.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }
});

export default router;
