/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const fetch = (path, commit, mutation) => axios
  .get(`./content/${path}.json`)
  .then((response) => {
    commit(mutation, response.data);
  })
  .catch((error) => {
    console.warn(
      `Произошла ошибка при попытке получить информацию о ${path}: ${error}`,
    );
  });

export default new Vuex.Store({
  state: {
    speakers: null,
    games: null,
    features: null,
  },
  getters: {
    filteredSpeakers({ speakers }) {
      return speakers?.filter((speaker) => speaker.location === 'Москва');
    },
    categories({ games }) {
      return games?.flatMap(({ category }) => category).filter((value, index, array) => array.indexOf(value) === index);
    },
  },
  mutations: {
    SET_SPEAKERS(state, speakers) {
      state.speakers = speakers;
    },
    SET_GAMES(state, games) {
      state.games = games.map((game) => {
        if (game.id === 'lean-tv') {
          return {
            ...game,
            id: 'Optima-tv',
            title: 'Optima TV',
          };
        }
        return game;
      });
    },
    SET_FEATURES(state, features) {
      state.features = features;
    },
  },
  actions: {
    async getSpeakers({ commit }) {
      await fetch('speakers', commit, 'SET_SPEAKERS');
    },
    async getFeatures({ commit }) {
      await fetch('features', commit, 'SET_FEATURES');
    },
    async getGames({ commit }) {
      await fetch('games', commit, 'SET_GAMES');
    },
  },
});
