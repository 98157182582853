import MainFooter from '@/components/MainFooter';
import MainHeader from '@/components/MainHeader';
import RouteGuard from '@/components/RouteGuard';
import MainFeatures from '@/components/MainFeatures.vue';
import CallbackForm from '@/components/CallbackForm.vue';
import GameList from '@/components/GameList.vue';
import SpeakerList from '@/components/SpeakerList.vue';
import GameForm from '@/components/GameForm.vue';

export default {
  MainFeatures, SpeakerList, GameList, CallbackForm, MainFooter, MainHeader, RouteGuard, GameForm,
};
