<template>
  <transition-group class="game-card-container" name="slide-fade" tag="section">
    <article
      v-for="game in filteredGames"
      :id="game.id"
      :key="game.id"
      class="game-card"
      :class="game.color"
    >
      <div class="game-card-title">
        <h3 class="game-card-heading">{{ game.title }}</h3>
        <p
          v-for="category in game.category"
          :key="category"
          class="game-card-category"
        >
          {{ category }}
        </p>
      </div>
      <img
        class="game-card-image"
        :src="`${publicPath}images/games/${game.id}.png`"
        :alt="game.title"
      />
      <div class="game-card-footer">
        <router-link
          class="button button_card-for game-card-button"
          :to="`/${game.id}`"
        >
          Подробнее
        </router-link>
        <span class="game-card-features">{{ game.people }} чел.</span>
      </div>
    </article>
  </transition-group>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GameList',
  props: {
    selectedCategory: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    ...mapState(['games']),
    filteredGames() {
      return this.selectedCategory === 'all'
        ? this.games
        : this.games.filter((product) => product.category.includes(this.selectedCategory));
    },
  },
};
</script>
<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to,
.slide-fade-leave {
  transform: translateX(30px);
  opacity: 0;
}

.game-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*width: 414px;*/
  /*height: 414px;*/
  padding: 32px 36px;
  border-radius: 30px;
  box-shadow: 0 16px 35px rgba(0, 0, 0, 0.25);

  /*position: relative;*/
  /*min-width: 300px;*/
  /*display: grid;*/
  /*grid-template-columns: 1fr 1fr;*/
  /*grid-template-areas: "cat cat" "head head" "img img" "btn props";*/
}

.game-card.blue {
  background-image: linear-gradient(172.75deg, #8088ff 0%, #5058ce 100%);
  .game-card-button {
    color: #5058ce;
  }
}

.game-card.orange {
  background-image: linear-gradient(172.75deg, #f5b452 0%, #ce933a 100%);
  .game-card-button {
    color: #ce933a;
  }
}

.game-card.liliac {
  background-image: linear-gradient(172.96deg, #cc9bed 0.84%, #a162cc 98.01%);
  .game-card-button {
    color: #a162cc;
  }
}

.game-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*width: 414px;*/
  /*height: 414px;*/
  padding: 32px 36px;
  border-radius: 30px;
  box-shadow: 0 16px 35px rgba(0, 0, 0, 0.25);
}

.game-card-container {
  margin-bottom: 120px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;

  @media (min-width: 576px) {
    grid-row-gap: 32px;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }

  @media (min-width: 992px) {
    grid-row-gap: 40px;
    grid-column-gap: 40px;
  }

  @media (min-width: 1200px) {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }

  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 46px;
    grid-column-gap: 46px;
  }
}

.game-card {
  height: auto;

  @media (min-width: 576px) {
    height: 440px;
  }

  @media (min-width: 768px) {
    height: 348px;
  }

  @media (min-width: 992px) {
    height: 460px;
  }

  @media (min-width: 1200px) {
    height: 364px;
  }

  @media (min-width: 1400px) {
    height: 424px;
  }
}

.game-card-image {
  width: 100px;
  height: 100px;
  margin: 24px 0;
}

@media (min-width: 768px) {
  .game-card-image {
    width: 120px;
    height: 120px;
  }
}

@media (min-width: 992px) {
  .game-card-image {
    width: 166px;
    height: 166px;
  }
}

@media (min-width: 1200px) {
  .game-card-image {
    width: 156px;
    height: 156px;
  }
}

.game-card-category {
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /*text-transform: capitalize;*/
  color: #ffffff;

  /*grid-area: cat;*/
}

.game-card-heading {
  padding: 0;
  margin: 0 0 8px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 28px;
  /*letter-spacing: 0.01em;*/
  color: #ffffff;

  /*grid-area: head;*/
}

.game-card-image {
  margin-bottom: 18px;
  /*margin: 42px 0 64px;*/
  width: 156px;
  height: 156px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  align-self: center;
  justify-self: center;
}

.game-card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.game-card-features {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /*line-height: 17px;*/
  text-transform: lowercase;
  color: #ffffff;

  align-self: center;
  justify-self: flex-end;
}
</style>
