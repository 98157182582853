<template>
  <div>
    <slot v-if="isOk" />
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'RouteGuard',
  data() {
    return {
      isOk: false,
    };
  },
  computed: {
    ...mapState(['games']),
  },
  created() {
    this.checkRoute();
  },
  beforeUpdate() {
    this.checkRoute();
  },
  methods: {
    checkRoute() {
      this.isOk = false;
      const { path } = this.$route;
      if (path === '/' || path === '/404' || this.games.some(({ id }) => path === `/${id}`)) {
        this.isOk = true;
        return;
      }
      this.$router.push('/404');
    },
  },

};
</script>
