<template>
  <div class="wrap">
    <section id="preview" class="preview-screen background-01">
      <img
        alt="🎓"
        src="@/assets/images/decoration-01.png"
        class="preview-screen__heading-icon"
      />
      <h1 class="preview-screen__heading">Бизнес-игры</h1>
      <p class="preview-screen__subheading">
        Академия бизнеса Б1 представляет новый формат обучения
      </p>
      <a href="#catalog" class="button button_main"> Выбрать игру </a>
    </section>

    <div class="container">
      <h2>Выбирайте игру по вкусу</h2>
    </div>

    <section id="catalog" class="container catalog">
      <ul class="catalog-navbar">
        <li class="catalog-filter">
          <label class="catalog-filter-label" :class="{selected: selectedCategory === 'all'}">
            <input
              v-model="selectedCategory"
              class="catalog-filter-input"
              type="radio"
              value="all"
              checked
            />
            Все
          </label>
        </li>
        <li
          v-for="category in categories"
          :key="category"
          class="catalog-filter-category"
        >
          <label class="catalog-filter-label" :class="{selected: selectedCategory === category}">
            <input
              v-model="selectedCategory"
              class="catalog-filter-input"
              type="radio"
              :value="category"
            />
            {{ category }}
          </label>
        </li>
      </ul>

      <game-list :selected-category="selectedCategory" />
    </section>

    <main-features />

    <div class="button-container">
      <a href="#callback" class="button button_main">Я не могу выбрать игру</a>
    </div>

    <section id="speakers">
      <div class="container">
        <h2>С Вами будут играть:</h2>
      </div>
      <speaker-list :speakers="filteredSpeakers" />
    </section>

    <div class="container">
      <h2>Есть вопросы?</h2>
      <p class="subheading">
        Оставьте заявку, мы свяжемся с Вами и поможем подобрать бизнес-игру
      </p>
    </div>

    <callback-form />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Catalog',
  data() {
    return {
      selectedCategory: 'all',
    };
  },
  computed: {
    ...mapState(['speakers', 'games']),
    ...mapGetters(['filteredSpeakers', 'categories']),
    filteredGames() {
      return this.selectedCategory === 'all'
        ? this.games
        : this.games.filter((product) => product.category.includes(this.selectedCategory));
    },
  },
};
</script>
<style lang="scss">
.catalog {
  margin-bottom: 80px;
}

.catalog-navbar {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 24px !important;
  margin-bottom: 32px !important;
  align-items: center;
}

.catalog-filter-input {
  display: none;
}

.catalog-filter-label {
  display: block;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  outline: 0;
  padding: 14px 24px;
  transition: 0.2s ease-out;
  margin-bottom: 16px;
  margin-right: 16px;
  font-weight: bold;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #2e2e38;
  color: #2e2e38;
  cursor: pointer;
}

.catalog-filter:first-of-type .catalog-filter-label.selected {
  color: #ffffff;
  background-color: #2e2e38;
}

.catalog-filter-input:checked + .catalog-filter-label,
.catalog-filter-input:focus + .catalog-filter-label,
.catalog-filter-label:hover {
  color: #ffffff;
  background-color: #2e2e38;
}

.catalog-filter-category:nth-of-type(3n) .catalog-filter-label {
  border: 1px solid #aa6ed3;
  color: #a96dd2;
}

.catalog-filter-category:nth-of-type(3n) .catalog-filter-label:hover,
.catalog-filter-category:nth-of-type(3n)
  .catalog-filter-input:checked
  + .catalog-filter-label,
.catalog-filter-category:nth-of-type(3n)
  .catalog-filter-input:focus
  + .catalog-filter-label,
  .catalog-filter-category:nth-of-type(3n) .catalog-filter-label.selected {
  color: white;
  background-color: #a96dd2;
}

.catalog-filter-category:nth-of-type(3n-1) .catalog-filter-label {
  border: 1px solid #8088ff;
  color: #8088ff;
}

.catalog-filter-category:nth-of-type(3n-1) .catalog-filter-label:hover,

.catalog-filter-category:nth-of-type(3n-1)
  .catalog-filter-input:checked
  + .catalog-filter-label,
.catalog-filter-category:nth-of-type(3n-1)
  .catalog-filter-input:focus
  + .catalog-filter-label,
  .catalog-filter-category:nth-of-type(3n-1) .catalog-filter-label.selected{
  color: white;
  background-color: #8088ff;
}

.catalog-filter-category:nth-of-type(3n + 1) .catalog-filter-label {
  border: 1px solid #f5b452;
  color: #f5b452;
}

.catalog-filter-category:nth-of-type(3n + 1) .catalog-filter-label:hover,
.catalog-filter-category:nth-of-type(3n + 1)
  .catalog-filter-input:checked
  + .catalog-filter-label,
.catalog-filter-category:nth-of-type(3n + 1)
  .catalog-filter-input:focus
  + .catalog-filter-label,
  .catalog-filter-category:nth-of-type(3n + 1) .catalog-filter-label.selected {
  color: white;
  background-color: #f5b452;
}

.preview-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  min-height: 720px;
}

.preview-screen__heading {
  padding: 0;
  margin: 0 0 12px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 120px;
  color: #2e2e38;
  text-align: center;
}

.preview-screen__subheading {
  display: inline-block;
  max-width: 672px;
  padding: 0;
  margin: 0 0 24px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #2e2e38;
}

.preview-screen__heading-icon {
  font-size: 64px;
  width: 100px;
  height: 100px;
  margin-bottom: -60px;
  z-index: 1000;
}

@media (max-width: 1023px) {
  .preview-screen__heading {
    font-size: 96px;
  }

  .preview-screen__heading-icon {
    width: 80px;
    height: 80px;
    margin-bottom: -30px;
  }
}

@media (max-width: 767px) {
  .preview-screen {
    min-height: 620px;
  }

  .preview-screen__heading {
    font-size: 72px;
  }
}

@media (max-width: 575px) {
  .preview-screen__heading {
    font-size: 42px;
  }

  .preview-screen__heading-icon {
    width: 60px;
    height: 60px;
    margin-bottom: -10px;
  }
}

.button-container {
  text-align: center;
  margin-bottom: 116px;
}
</style>
