<template>
  <div class="speakers container" tag="section">
    <article
      v-for="speaker in speakers"
      :key="speaker.id"
      class="speakers-item"
      :class="{ blue: speaker.male }"
    >
      <img
        :src="`${publicPath}images/speakers/${speaker.id}.jpg`"
        :alt="speaker.name"
        class="speakers-item-photo"
      />
      <div class="speakers-item-body">
        <h3>{{ speaker.name }}</h3>
        <p>{{ speaker.description }}</p>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'SpeakerList',
  props: {
    speakers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
<style lang="scss" scoped>
.speakers {
  display: grid;
  margin-bottom: 80px;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 24px;

  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 32px;
  }

  &-item {
    color: white !important;
    overflow: hidden;
    border-radius: 30px;
  }

  &-item {
    background-image: linear-gradient(148.97deg, #cc9bed 0.84%, #a162cc 98.01%);
    &.blue {
      background-image: linear-gradient(
        172.75deg,
        #8088ff 0%,
        #5058ce 100%
      ) !important;
    }
  }

  &-item-photo {
    height: 350px;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    object-position: top;
  }

  &-item-body {
    padding: 16px 35px 35px;
  }
}
</style>
